import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Navbar from '../components/navbar/Navbar';

//Tabla
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
//footer
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage'
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';

import axios from "axios";
import Swal from "sweetalert2";
import Input from '@mui/material/Input';

import autoriza_coljuegos from "../images/footer_terminos_condiciones/Grupo 2623.svg";
import logo_coljuegos from "../images/footer_terminos_condiciones/Grupo 2624.svg";
import logo_18 from "../images/footer_terminos_condiciones/Grupo 2625.svg";

import "../sass/Tablas.sass"

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#002C8D',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: '#002C8D',
  },
}));
const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(2n+1)': {
    backgroundColor: '#C4E5FF',

  },
  '&:nth-of-type(2n)': {
    backgroundColor: '#FFF',
  },
  '&:last-of-type': {
    borderBottom: 'none',
  },
  margin: '8px 0',
  // hide last border

}));
function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

function SegundoTorneo() {
  window.sessionStorage.setItem("one", "true");
  window.sessionStorage.setItem("two", "true");
  window.sessionStorage.setItem("four", "true");
  window.sessionStorage.setItem("three", "false");
  window.sessionStorage.setItem("text1", "");

  const baseURL = "https://api.torneosbetplay.com.co/";
  // const baseURL = "http://localhost:8001/";

  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([]);
  const [img_banner, setImg_banner] = useState("");
  const [Once, setOnce] = useState([]);
  const [titulo, setTitulo] = useState("");
  const [titulo2, setTitulo2] = useState("");
  const [titulo3, setTitulo3] = useState("");
  const [titulo4, setTitulo4] = useState("");
  const [dataSelect, setDataSelect] = useState([])
  const [wordEntered, setWordEntered] = useState("");

  const terminos_y_condiciones = "Jugar sin control causa adicción, el juego es entretenimiento, juega con moderación. Prohibida la venta a menores de edad.";

  function createData2(posicion, usuario, valorApostado, valorPremio) {
    return { posicion, usuario, valorApostado, valorPremio };
  }

  //pagination - footer
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [reloadData, setReloadData] = useState(true);

  useEffect(() => {
    // Traer todo los torneos
    cargarDatosSelect();
  }, []);

  useEffect(() => {
    // Traer registros por paginación
    if (reloadData) {
      fractionedDataTable2().then(() => {
        setTimeout(() => {
          Swal.close();
        }, 750)
      })
    } else {
      searchHandleResults()
    }
  }, [page, rowsPerPage, reloadData]);

  //search
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      let searchWord = '';
      if (searchTerm === '') {
        setPage(0);
        setRowsPerPage(parseInt(10, 10));
        searchWord = '!';
      } else { searchWord = searchTerm; }
      //handleFilter(searchWord);
    }, 450)

    return () => clearTimeout(delayDebounceFn)
  }, [searchTerm])

  const cargarDatosSelect = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let data = await axios.get(baseURL + "api/get/torneo/all").then(e => {

      if (e.data.length == 0) {
        setDataSelect([{ hola: 'flag' }])
        //setChargeSuccesful(false)
        Swal.close();
      } else {
        setDataSelect(e.data.torneos)
        //setChargeSuccesful(true)
        e.data.torneos.filter(element => element.activo).forEach((element, index) => {
          if (element.posicion == 1) {
            setTitulo(element.titulo)

          }
          else if (element.posicion == 2) {
            setTitulo2(element.titulo)
            setImg_banner(element.bannerData)
          }
          else if (element.posicion == 3) {
            setTitulo3(element.titulo)
          }
          else if (element.posicion == 4) {
            setTitulo4(element.titulo)
          }
        })
        Swal.close();
      }
    });
  }

  const fractionedDataTable2 = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let dataTable = await axios.get(
      baseURL + "api/get/segundo-torneo/slice/" + page + "/" + rowsPerPage + ""
    );
    setFilteredData(dataTable.data.segundotorneos);
    setTotalRows(dataTable.data.length)

    if (Once.length === 0) {
      setOnce([{}]);
    }

  };

  const searchHandleResults = () => {
    let searchWord = '';
    if (searchTerm === '') {
      setReloadData(true)
      searchWord = '!';
    } else {
      searchWord = searchTerm;
      handleFilter(searchWord);
    }
  }

  const handleFilter = async (event) => {
    setReloadData(false)
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    /*const searchWord = event.target.value;
    setWordEntered(searchWord);
    const newFilter = data.filter((value) => {
      return value.usuario.toLowerCase().includes(searchWord.toLowerCase());
    });

    i
    }*/
    //---------------------
    //console.log(event);
    const searchWord = event;
    let filterWord;
    const pattern = new RegExp('^[A-Z0-9]+$', 'i');

    setWordEntered(searchWord);

    if (!pattern.test(searchWord)) {
      filterWord = '!'
    } else {
      filterWord = searchWord
    }

    let filteredDataAxi;

    filteredDataAxi = await axios.get(
      baseURL + "api/get/segundo-torneo/search/" + filterWord + "?page=" + page + "&limit=" + rowsPerPage + ""
    );

    /*filteredDataAxi = await axios.get(
      baseURL + "api/get/segundo-torneo/search/" + filterWord
    );*/

    setFilteredData(filteredDataAxi.data.resultado)
    setTotalRows(filteredDataAxi.data.length)

    Swal.close();
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const tablaD = () => {
    let auxRows = []

    filteredData.forEach(element => {
      auxRows.push(createData2(element.posicion, element.usuario, element.valorApostado, element.valorPremio, 0));
    });

    const rows = auxRows
    let newFontSize = 14
    if (window.innerWidth < 1100) {
      newFontSize = 14
    }

    return (
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ fontSize: newFontSize }} className='row_table' align="center"><h4>Posición</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Usuario</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Puntaje</h4></StyledTableCell>
              <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>Premio</h4></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.length > 0 && rows.map((row) => (
              <StyledTableRow key={row.name}>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center">
                  <h4>{row.posicion}</h4>
                </StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.usuario}</h4></StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.valorApostado}</h4></StyledTableCell>
                <StyledTableCell style={{ fontSize: newFontSize }} align="center"><h4>{row.valorPremio}</h4></StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              {window.innerWidth > 992
                ? <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={4}
                  count={totalRows}
                  labelRowsPerPage={""}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    inputProps: {
                      'aria-label': 'Celdas visibles',
                    },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActions}
                />
                : window.innerHeight < 400
                  ? <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    colSpan={4}
                    count={totalRows}
                    labelRowsPerPage={""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Celdas visibles',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                  : <TablePagination
                    rowsPerPageOptions={[10, 25]}
                    colSpan={4}
                    count={totalRows}
                    labelRowsPerPage={""}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'Celdas visibles',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
              }

            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    )
  }

  return (
    <>

      {/*
        <Navbar id="navbarsito"/>
          <div style={{zIndex:"10",}}>
            <SearchBar placeholder="Ingresa los últimos 4 dígitos de tu cédula..." table={1} />
            <Divider />
          </div>
        */}

      <body className='body_primer_torneo'>
        <div className='container_tablas'>
          <div className='content_area group'>
            <aside className='above_area_torneo'>
              <div className='navbar_above'>
                <Navbar className="navbarsito" data={{ "titulos": [titulo, titulo2, titulo3, titulo4] }} id="navbarsito" />
              </div>
              <div className='central_logo_above'>
                <p>Ranking <b>Torneos</b> <b className='b_bigger'> BetPlay</b></p>
                <img
                  src={require("../images/Logo try.png")}
                />
              </div>
              <div className='return_above'>
                <a href="/">
                  <p>X</p>
                </a>
              </div>
            </aside>
          </div>
          <div className='content_area group'>
            <div className='main_area_torneo'>

              <img
                src={img_banner}

              />

              <p style={{ marginLeft: "5%", marginRight: "30%", width: "100%", fontSize: "26px", fontWeight: "lighter" }}>Ranking</p>
              <p style={{ marginLeft: "5%", marginRight: "30%", width: "100%", fontSize: "26px", fontWeight: "900" }}>{titulo2}</p>
              <h4 className='h4_title'>
                Ingresa los cuatro últimos dígitos de tu cédula
              </h4>
              <div>
                <div className='buscar_input_div'>
                  <Input className='buscar_input'
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </div>
                <div className='buscar_button_div'>
                  <Button className='buscar_button' onClick={searchHandleResults}>
                    Buscar
                  </Button>
                </div>
              </div>
              <div className='area_tablas'>
                {tablaD()}
              </div>

            </div>

            {window.innerWidth > 992
              ? <aside className='right_area_torneo'>

              </aside>
              : <></>

            }

            <div className='footer_2'>
              <div className='terminos_condiciones_img_div'>

                <img
                  src={logo_18}

                  className='img_tight1'

                  width="38px"
                  height="30px"
                />
                <img
                  src={logo_coljuegos}

                  className='img_tight2'

                  width="38px"
                  height="30px"
                />
                <img
                  src={autoriza_coljuegos}

                  width="85px"
                  height="30px"
                />
              </div>
              <aside>
                <h2>
                  {terminos_y_condiciones}
                </h2>
              </aside>

            </div>

          </div>

        </div>


      </body>
    </>
  );
}

export default SegundoTorneo;