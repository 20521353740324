import React, { useState, useEffect } from 'react';
import Navbar from '../components/navbar/Navbar';
import {Box, Button, Dialog, DialogActions,
        DialogContent, DialogContentText, DialogTitle,
        FormControl, FormControlLabel, InputLabel,
        MenuItem, Select, NativeSelect, InputBase, 
        TextField} from '@mui/material'

import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Swal from "sweetalert2";

import {MdCloudUpload} from 'react-icons/md';

import { styled } from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';

//Excel
import * as XLSX from "xlsx";

import "../sass/Carga.sass"

const CssTextField = styled(TextField)({
  '& label.Mui-focused': {
    color: '#fff',
    
  },
  '& label.Mui-disabled': {
    color: "#fff"
  },
  '& .MuiInput-underline': {
    borderColor: '#fff',
  },
  '&:disabled ': {
    borderColor: "#fff",
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
    },
    '&:hover fieldset': {
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
      color: "#FFF"
    },
    '&.Mui-disabled fieldset': {
      borderColor: "#fff",
      color: "#FFF"
    },

    '& .Mui-disabled': {
      cursor: 'not-allowed',
      '& *': {
        cursor: 'not-allowed !important',
      },
    },
    
  },
  '& .MuiInputBase-root.Mui-disabled': {
    backgroundColor: '#ffffff42',
  },
});

const CssCheckbox = styled(Checkbox)({
  color: "#fff",
  '&.Mui-checked': {
  color: "#fff",
    },
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    boxShadow: '0 0 0 0.2rem #fff',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#fff',
      boxShadow: '0 0 0 0.2rem #fff',
    },
  },
}));

function Carga() {
  const baseURL = "https://api.torneosbetplay.com.co/";
  // const baseURL = "http://localhost:8001/";

  const [dataSelect, setDataSelect] = useState([])
  const [chargeSuccesful, setChargeSuccesful] = useState(false)

  const [open, setOpen] = useState(false);
  const [torneoNameValue, setTorneoNameValue] = useState('');
  const [torneoNameValueSelect, setTorneoNameValueSelect] = useState('');
  const [torneoCargado, setTorneoCargado] = useState('');

  //Datos crear torneo
  const [imgCache,setImgCache] = useState('')
  const [fileTypeImage,setFileTypeImage] = useState('')
  const [imgCacheBanner,setImgCacheBanner] = useState('')
  const [fileTypeImageBanner,setFileTypeImageBanner] = useState('')
  const [excelCache,setExcelCache] = useState('')
  const [fileTypeImageExcel,setFileTypeImageExcel] = useState('')
  const [excelName, setExcelName] = useState('');
  const [titulo, setTitulo] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [activado, setActivado] = useState(false);
  const [numeroActivado, setNumeroActivado] = useState(0);

  const [disableTitulo, setDisableTitulo] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClean = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    await axios.delete(baseURL + "api/delete/torneo/" + torneoNameValue).then(e =>{
      Swal.close();
    }).then(() => {
      toast.success(`Se elimino el torneo exitosamente`);
      window.location.reload();
    });
  };

  const handleRemoveTorneoName = (event) => {
    setTorneoNameValue(
      event.target.value,
    );
  };

  const handleSelectTorneoName = (event) => {
    setTorneoNameValueSelect(
      event.target.value,
    );
  };

  const handleChangeCheckbox = () => {
    
    if(!activado){
      setNumeroActivado(numeroActivado + 1)
    }
    else{
      setNumeroActivado(numeroActivado - 1)
    }

    setActivado(!activado)
    
  };

  const imagebase64 = async (file) => {
    setFileTypeImage(file.type)
    const reader = new FileReader()
    await reader.readAsDataURL(file)
    const data = new Promise((resolve,reject) => {
      reader.onload = () => resolve(reader.result)
      reader.onerror = (err) => reject(err)
    })
    return data;
  };

  const imagebase64Banner = async (file) => {
    setFileTypeImageBanner(file.type)
    const reader = new FileReader()
    await reader.readAsDataURL(file)
    const data = new Promise((resolve,reject) => {
      reader.onload = () => resolve(reader.result)
      reader.onerror = (err) => reject(err)
    })
    return data
  }

  const handleUploadImage = async (e) => {
    const file = e.target.files[0]
    const image = await imagebase64(file)
    setImgCache(image)
  }

  const handleUploadImageBanner = async (e) => {
    const file = e.target.files[0]
    const image = await imagebase64Banner(file)
    setImgCacheBanner(image)
  }

  let cargarDatosSelect = async () =>{
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let data = await axios.get(baseURL + "api/get/torneo/all").then(e =>{
      //console.log(e.data);
      //console.log(e.data.torneos)
      //console.log(e.data.length)
      if(e.data.length == 0){
        //setDataSelect([{hola:'flag'}])
        setChargeSuccesful(false)
        Swal.close();
      }else{
        setDataSelect(e.data.torneos)
        setChargeSuccesful(true)
        setNumeroActivado(
          e.data.torneos.filter(element => element.activo).length
        )
        Swal.close();
      }
    });
  }

  function obtenerSiguientePosicionDisponible(items) {
    // Obtener todas las posiciones existentes en el array
    const posiciones = items.map(item => item.posicion).sort((a, b) => a - b);

    // Encontrar la primera posición que falte en la secuencia
    const posicionDisponible = posiciones.find((posicion, index) => posicion !== index + 1);

    // Si no hay huecos, la siguiente posición disponible es el siguiente número en la secuencia
    return posicionDisponible ? posiciones.indexOf(posicionDisponible) + 1 : posiciones.length + 1;
  }

  const addTorneo = async () => {
      
      let permiso = 0;

      //console.log(dataSelect)
      //console.log(torneoCargado)
      dataSelect.forEach(element => {
        if(element.torneoName == torneoCargado){
          permiso++;
        }
      });
      
      if(permiso == 0){

        await axios.post(
          baseURL + "api/create/torneo",
          {
            torneoName: titulo,
            bannerData: imgCacheBanner,
            bannerContentType: fileTypeImageBanner,
            imageData: imgCache,
            imageContentType: fileTypeImage,
            excelName: "",
            excelData: "",
            excelContentType: "",
            titulo: titulo,
            descripcion: descripcion,
            activo: activado,
            posicion: obtenerSiguientePosicionDisponible(dataSelect)
          }
        ).then(() => {
          Swal.close()
          toast.success(`Se creo el torneo exitosamente`);
          window.location.reload();
        })

      }
      else{
        await axios.patch(
          baseURL + "api/update/torneo",
          {
            torneoName: torneoCargado,
            bannerData: imgCacheBanner,
            bannerContentType: fileTypeImageBanner,
            imageData: imgCache,
            imageContentType: fileTypeImage,
            excelName: "",
            excelData: "",
            excelContentType: "",
            titulo: titulo,
            descripcion: descripcion,
            activo: activado,
          }
        ).then(() => {
          Swal.close()
          toast.success(`Se actualizo el torneo exitosamente`);
          window.location.reload();
        })
      }
      
  }

  const cargarTorneo = () => {
    cargarDatosSelect();
    const searchTorneoData = dataSelect.filter(element => element.torneoName == torneoNameValueSelect);
    
    setTorneoCargado(torneoNameValueSelect);

    if(searchTorneoData.length == 0){
      setTitulo("")
      setFileTypeImageBanner("")
      setImgCacheBanner("")
      setFileTypeImage("")
      setImgCache("")
      setExcelName("")
      setExcelCache("")
      setFileTypeImageExcel("")
      setTitulo("")
      setDescripcion("")
      setActivado(false)
      setDisableTitulo(false)
    }
    else{
      setTitulo(searchTorneoData[0].titulo)
      setFileTypeImageBanner(searchTorneoData[0].bannerContentType)
      setImgCacheBanner(searchTorneoData[0].bannerData)
      setFileTypeImage(searchTorneoData[0].imageContentType)
      setImgCache(searchTorneoData[0].imageData)
      setExcelName(searchTorneoData[0].excelName)
      setExcelCache(searchTorneoData[0].excelData)
      setFileTypeImageExcel(searchTorneoData[0].excelContentType)
      setDescripcion(searchTorneoData[0].descripcion)
      setActivado(searchTorneoData[0].activo)
      setDisableTitulo(false)
    }
  };

  const agregarDisabled = () => {
    if(titulo == '' || fileTypeImageBanner == '' ||
        fileTypeImage == '' || descripcion == ''
      ){
      return true
    }
    else{return false}
  }

  const eliminarDisabled = () => {
    if(!chargeSuccesful){
      return true
    }else{
      return false
    }
  }

  const actualizarDisabled = () => {
    if(torneoNameValueSelect == ''){
      return true
    }else{
      return false
    }
  }

  const activadoDisabled = () => {

    if(numeroActivado >= 4  && !activado){

      return true;

    }
    else{

      return false;
      
    }

  }

  useEffect(() => {
    cargarDatosSelect();
  }, [])

  return (
    <>
      <div style={{marginTop:'2%'}}></div>
      <Navbar data = {{"titulos" : []}}/>
      <div className='primer-torneo' style={{marginTop:'2%', marginBottom:'3%'}}>
        
      </div>
      <div className='carga_excel_div'>

        <div className='carga_banner_div'>

            <div className='form_titulo'>
              <h1> Carga de torneos</h1>
            </div>

            <div className='form_select'>
              <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                    color: 'white'
                  }}
                >
                  <FormControl sx={{ mt: 2, minWidth: 120}}>
                    <InputLabel style={{color:'white', marginTop:"10%", marginLeft:"-10%"}} htmlFor="max-width">Torneos</InputLabel>
                    <Select
                      className='MenuItem_torneo'
                      focused
                      value={torneoNameValueSelect}
                      onChange={handleSelectTorneoName}
                      label="Torneos"
                      input={<BootstrapInput/>}
                      inputProps={{
                        name: 'max-width',
                        id: 'max-width',
                      }}
                    >
                      <MenuItem value={"Nuevo"}> <p> Nuevo </p>  </MenuItem>
                      {dataSelect.map((menuValue) => (
                        <MenuItem value={menuValue.torneoName}> <p>{menuValue.torneoName}</p>  </MenuItem>
                      ))}
                    </Select>
                    
                  </FormControl>
              </Box>
            </div>

            <div className='form_update'>
              <Button
                  disabled={actualizarDisabled()}
                  className='button_upload' 
                  variant="outlined"
                  onClick={cargarTorneo}
                >
                  Cargar Torneo
              </Button>
            </div>

            <div className='form_nombre'>
                  <CssTextField
                    focused
                    sx={{ input: { color: 'white' } }}
                    //disabled={disableTitulo}
                    className='image_name'
                    variant='outlined'
                    onChange={(e) => setTitulo(e.target.value)}
                    value={titulo}
                    label='Titulo del torneo'
                  />
                
            </div>

            <div className='form_descripcion'>
              <CssTextField

                focused
                sx={{ input: { color: 'white' } }}
                className='image_name'
                variant='outlined'
                onChange={(e) => setDescripcion(e.target.value)}
                value={descripcion}
                label='Descripcion'
              />
            </div>
            
            <div className='form_img'>
              <h2>Imagen para el carrusel</h2>
              <div className='imageContainer'>
                <form>
                  <label>
                    <div className='uploadBox'>
                      <input type='file' id='uploadImageBanner' onChange={handleUploadImage} className='input_file_image'></input>
                      { imgCache ? <img src={imgCache}/> : <MdCloudUpload/>}
                    </div>
                  </label>
                </form>
              </div>
            </div>

            <div className='form_img_banner'>
              <h2>Imagen para el banner</h2>
              <div className='imageContainer'>
                <form>
                  <label>
                    <div className='uploadBox'>
                      <input type='file' id='uploadImage' onChange={handleUploadImageBanner} className='input_file_image'></input>
                      { imgCacheBanner ? <img src={imgCacheBanner}/> : <MdCloudUpload/>}
                    </div>
                  </label>
                </form>
              </div>
            </div>

            <div className='form_activado'>
              <div className={activadoDisabled() ? 'div_form' : ''}  >
              <FormControlLabel
                className='form_control_label'
                sx={{color:"#fff"}}
                disabled={activadoDisabled()} 
                control={
                  <CssCheckbox 
                    className='checkbox'
                    disabled={activadoDisabled()} 
                    focused 
                    onChange={handleChangeCheckbox}
                    checked={activado}
                  />
                }
                label="Activado" 
              />
              <>
              {activadoDisabled() ? <p>Ya hay 4 torneos con activo</p>:<></>}
              </>
              </div>
              
            </div>

            <div className='form_submit'>
              <Button
                disabled={agregarDisabled()}
                className='button_upload' 
                variant="outlined"
                onClick={addTorneo}
              >
                Guardar Torneo
              </Button>
              <Button 
                disabled={eliminarDisabled()} 
                className='button_delete' 
                variant="outlined" 
                onClick={handleClickOpen}
              >
                Eliminar Torneo
              </Button>
            </div>
            
            <Dialog
              fullWidth={true}
              maxWidth='sm'
              open={open}
              onClose={handleClose}
            >
              <DialogTitle>Eliminar torneos</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Escoge por el nombre del torneo el que quieras eliminar
                </DialogContentText>
                <Box
                  noValidate
                  component="form"
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    m: 'auto',
                    width: 'fit-content',
                  }}
                >
                  <FormControl sx={{ mt: 2, minWidth: 120 }}>
                    <InputLabel htmlFor="max-width">Torneos</InputLabel>
                    <Select
                      autoFocus
                      value={torneoNameValue}
                      onChange={handleRemoveTorneoName}
                      label="TorneosEliminar"
                      inputProps={{
                        name: 'max-width',
                        id: 'max-width',
                      }}
                    >
                      {dataSelect.map((menuValue) => (
                        <MenuItem value={menuValue.torneoName}> {menuValue.torneoName} </MenuItem>
                      ))}
                    </Select>
                    
                  </FormControl>
                </Box>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClean}>Eliminar</Button>
                <Button onClick={handleClose}>Cerrar</Button>
              </DialogActions>
            </Dialog>
        </div>

      </div>
    </>
  );
}

export default Carga;