import React, { useState, useEffect } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { SidebarData } from './SidebarData';
import './Navbar.css';
import { IconContext } from 'react-icons';
import { Grid } from "@material-ui/core";

function Navbar( {data} ) {
  const [sidebar, setSidebar] = useState(false);
  const [titulo, setTitulo] = useState("titulo");

  const showSidebar = () => setSidebar(!sidebar);

  useEffect(() => {
    console.log(data)
  }, []);

  return (
    <>
      <div>
        <IconContext.Provider value={{ color: '#fff' }}>
            <Link to='#' className='menu-bars'>
              <FaIcons.FaBars style={{ color: '#fff' }} onClick={showSidebar} />
            </Link>
          <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
            <ul className='nav-menu-items' onClick={showSidebar}>
              <li className='navbar-toggle'>
                <Link to='#' className='menu-bars'>
                  <AiIcons.AiOutlineClose style={{ color: 'white' }}/>
                </Link>
              </li>
              {SidebarData.map((item, index) => {
                return (
                  <li key={index} className={item.cName}>
                    <Link to={item.path}>
                    
                      
                      <span>
                        {
                          data.titulos.length == 0
                          ?item.title
                          :data.titulos[index] == "" ? item.title :data.titulos[index]
                        }
                        </span>
                    </Link>
                  </li>
                );
              })}
            </ul>
          </nav>
        </IconContext.Provider>
      </div>
    </>
  );
}

export default Navbar;