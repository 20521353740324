import React, { useState } from "react";
import * as XLSX from "xlsx";
import Button from "@material-ui/core/Button";
import Input from "@material-ui/core/Input";
import axios from "axios";
import Swal from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "../../sass/excel.sass"

function Excel() {
  const baseURL = "https://api.torneosbetplay.com.co/";
  //const baseURL = "http://localhost:8001/";

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);
  const [data4, setData4] = useState([]);
  const [parrafo, setParrafo] = useState("");
  const [parrafo2, setParrafo2] = useState("");

  const postExcel = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let promises = [];
    const sizeOfReq = 100;
    await axios.delete(baseURL + "api/delete/primer-torneo");
    for (let i = 0; i < data.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                baseURL + "api/create/primer",
                data.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
          }
        })
      );
    }
    await Promise.all(promises);
    Swal.close();
    toast.success(`Se ha montado el excel exitosamente`);
  };

  const postExcel2 = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    let promises = [];
    const sizeOfReq = 100;

    await axios.delete(baseURL + "api/delete/segundo-torneo");

    for (let i = 0; i < data2.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                baseURL + "api/create/segundo",
                data2.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
          }
        })
      );
    }

    await Promise.all(promises);
    Swal.close();
    toast.success(`Se ha montado el excel exitosamente`);

  }

  const postExcel3 = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    let promises = [];
    const sizeOfReq = 100;

    await axios.delete(baseURL + "api/delete/tercero-torneo");

    for (let i = 0; i < data3.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                baseURL + "api/create/tercer",
                data3.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
          }
        })
      );
    }

    await Promise.all(promises);
    Swal.close();
    toast.success(`Se ha montado el excel exitosamente`);

  }

  const postExcel4 = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    let promises = [];
    const sizeOfReq = 100;

    await axios.delete(baseURL + "api/delete/cuarto-torneo");

    for (let i = 0; i < data4.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                baseURL + "api/create/cuarto",
                data4.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
          }
        })
      );
    }

    await Promise.all(promises);
    Swal.close();
    toast.success(`Se ha montado el excel exitosamente`);
  }

  const addExcel = async () => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();
    let promises = [];
    const sizeOfReq = 100;
    for (let i = 0; i < data.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                baseURL + "api/create/primer",
                data.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
          }
        })
      );
    }
    for (let i = 0; i < data2.length / sizeOfReq; i++) {
      promises.push(
        new Promise(async (resolve, reject) => {
          try {
            resolve(
              await axios.post(
                baseURL + "api/create/segundo",
                data2.slice(i * sizeOfReq, (i + 1) * sizeOfReq)
              )
            );
          } catch {
            reject(new Error("Error"));
            Swal.close();
            toast.error(`Se ha montado el excel exitosamente`);
          }
        })
      );
    }
    await Promise.all(promises);
    Swal.close();
    toast.success(`Se ha montado el excel exitosamente`);
  };

  const postParrafo = async () => {
    console.log(parrafo);
    let boolSubtitulo = await axios.get(baseURL + "api/check/subtitulos/0");
    console.log(boolSubtitulo.data.existe);
    if (boolSubtitulo.data.existe) {
      let auxSubtitulo = await axios.get(baseURL + "api/get/subtitulos/id/0");
      let subtituloPatch = {
        id: 0,
        primerParrafo: parrafo,
        segundoParrafo: auxSubtitulo.data.subtitulos.segundoParrafo,
      };
      await axios.post(
        baseURL + "api/update/primer-subtitulos",
        subtituloPatch
      );
    } else {
      let subtituloPatch = {
        id: 0,
        primerParrafo: parrafo,
        segundoParrafo: "",
      };
      await axios.post(baseURL + "api/crear/subtitulos", subtituloPatch);
    }

    toast.success(`Titulo de la pagina torneo 1 ACTUALIZADO`);
  };

  const postParrafo2 = async () => {
    console.log(parrafo2);
    if (parrafo2 === "") {
      console.log("parrafo2 vacio");
    }
    let boolSubtitulo = await axios.get(baseURL + "api/check/subtitulos/0");
    console.log(boolSubtitulo.data.existe);
    if (boolSubtitulo.data.existe) {
      let auxSubtitulo = await axios.get(baseURL + "api/get/subtitulos/id/0");
      let subtituloPatch = {
        id: 0,
        primerParrafo: auxSubtitulo.data.subtitulos.primerParrafo,
        segundoParrafo: parrafo2,
      };
      console.log(subtituloPatch);
      await axios.post(
        baseURL + "api/update/segunda-subtitulos",
        subtituloPatch
      );
    } else {
      let subtituloPatch = {
        id: 0,
        primerParrafo: "",
        segundoParrafo: parrafo2,
      };
      await axios.post(baseURL + "api/crear/subtitulos", subtituloPatch);
    }
    toast.success(`Titulo de la pagina torneo 2 ACTUALIZADO`);
  };

  const handFile1 = async (e) => {
    const file = e.target.files[0];
    const dataTable = await handFileTry(file);
    setData(dataTable);
  }
  const handFile2 = async (e) => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    const file = e.target.files[0];

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheet2 = workbook.Sheets[workbook.SheetNames[1]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });

    let dataTable = [];
    let header;
    let index = 0;

    jsonData.forEach((json) => {
      if (index < 1) {
        index++;
        header = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
      } else {
        let bodyColumn = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
        dataTable.push(bodyColumn);
      }
    });
    
    setData2(dataTable);
    Swal.close();
    toast.success(`El excel ya está listo para ser montado`);
  };

  const handFile3 = async (e) => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    const file = e.target.files[0];

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheet2 = workbook.Sheets[workbook.SheetNames[1]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });

    let dataTable = [];
    let header;
    let index = 0;

    jsonData.forEach((json) => {
      if (index < 1) {
        index++;
        header = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
      } else {
        let bodyColumn = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
        dataTable.push(bodyColumn);
      }
    });
    
    setData3(dataTable);
    Swal.close();
    toast.success(`El excel ya está listo para ser montado`);
  };
  const handFile4 = async (e) => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    const file = e.target.files[0];

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheet2 = workbook.Sheets[workbook.SheetNames[1]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });

    let dataTable = [];
    let header;
    let index = 0;

    jsonData.forEach((json) => {
      if (index < 1) {
        index++;
        header = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
      } else {
        let bodyColumn = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
        dataTable.push(bodyColumn);
      }
    });
    
    setData4(dataTable);
    Swal.close();
    toast.success(`El excel ya está listo para ser montado`);
  };

  const handFileTry = async (data) => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];

    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });

    let dataTable = [];
    let header;
    let index = 0;

    jsonData.forEach((json) => {
      if (index < 1) {
        index++;
        header = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
      } else {
        let bodyColumn = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
        dataTable.push(bodyColumn);
      }
    });

    Swal.close();
    toast.success(`El excel ya está listo para ser montado`);
    return dataTable;
  };

  const handFile = async (e) => {
    Swal.fire({ title: "Cargando", allowOutsideClick: false });
    Swal.showLoading();

    const file = e.target.files[0];

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheet2 = workbook.Sheets[workbook.SheetNames[1]];
    const jsonData = XLSX.utils.sheet_to_json(worksheet, {
      header: 1,
      defval: "",
    });

    let dataTable = [];
    let header;
    let index = 0;

    jsonData.forEach((json) => {
      if (index < 1) {
        index++;
        header = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
      } else {
        let bodyColumn = {
          posicion: json[0],
          usuario: json[1],
          valorApostado: json[2],
          valorPremio: json[3],
        };
        dataTable.push(bodyColumn);
      }
    });
    
    setData(dataTable);
    Swal.close();
    toast.success(`El excel ya está listo para ser montado`);
  };

  return (
    <>
      <ToastContainer style={{ paddingBottom: "30rem" }} />
      <div className="base">
        <div className="primer-torneo">
          <div className="div_input_file"  style={{ marginTop: "4rem" }}>
            <Input className="input_texto"
              type="file"
              onChange={(e) => handFile(e)}
              style={{
                color: "#002C8D",
                fontSize: "bold",
              }}
            />
          </div>

          <div style={{marginTop: "4rem" }}>
            <Button className="excel_button_file"
              variant="contained"
              onClick={postExcel}
              
            >
              Montar excel tabla 1
            </Button>
          </div>
        </div>

        <div className="primer-torneo">
          <div className="div_input_file" style={{ marginTop: "4rem" }}>
            <Input className="input_texto"
              type="file"
              onChange={(e) => handFile2(e)}
              style={{
                color: "#002C8D",
                fontSize: "bold",
              }}
            />
          </div>

          <div style={{marginTop: "4rem" }}>
            <Button className="excel_button_file"
              variant="contained"
              onClick={postExcel2}
              
            >
              Montar excel tabla 2
            </Button>
          </div>
        </div>

        <div className="primer-torneo">
          <div className="div_input_file"  style={{ marginTop: "4rem" }}>
            <Input className="input_texto"
              type="file"
              onChange={(e) => handFile3(e)}
              style={{
                color: "#002C8D",
                fontSize: "bold",
              }}
            />
          </div>

          <div style={{marginTop: "4rem" }}>
            <Button className="excel_button_file"
              variant="contained"
              onClick={postExcel3}
              
            >
              Montar excel tabla 3
            </Button>
          </div>
        </div>

        <div className="primer-torneo">
          <div className="div_input_file" style={{ marginTop: "4rem" }}>
            <Input className="input_texto"
              type="file"
              onChange={(e) => handFile4(e)}
              style={{
                color: "#002C8D",
                fontSize: "bold",
              }}
            />
          </div>

          <div style={{marginTop: "4rem" }}>
            <Button className="excel_button_file"
              variant="contained"
              onClick={postExcel4}
              
            >
              Montar excel tabla 4
            </Button>
          </div>
        </div>

      </div>
      

      <div
        style={{
          display: "flex",
          fontSize: "1.5rem",
        }}
      ></div>
    </>
  );
}

export default Excel;
