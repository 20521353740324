import React, { useState, useEffect } from 'react';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import axios from "axios";
import Swal from "sweetalert2";

import * as XLSX from "xlsx";

import "../Carousel/Carousel_3_finalizado.sass"

const Carousel_3_finalizado = ( {data} ) => {
    const baseURL = "https://api.torneosbetplay.com.co/";
    //const baseURL = "http://localhost:8001/";

    const [indexCarousel, setIndexCarousel] = useState(0)
    const [dataSelect, setDataSelect] = useState([])

    const nextSlide = () => {
        setIndexCarousel(indexCarousel === data.length - 1 ? 0 : indexCarousel + 1);
    }

    const previousSlide = () => {
        setIndexCarousel(indexCarousel === 0 ? data.length - 1 : indexCarousel - 1);
    }

    const cargarDatosSelect = async () =>{
        Swal.fire({ title: "Cargando", allowOutsideClick: false });
        Swal.showLoading();
        let data = await axios.get(baseURL + "api/get/torneo/all").then(e =>{
    
          if(e.data.length == 0){
            Swal.close();
          }else{
            setDataSelect(e.data.torneos)
            Swal.close();
          }
        });
      }

    const handleDonwloadExcel = (index) => {
        //e.preventDefault()
        const decodedData = JSON.parse(dataSelect[index].excelData)
        //setBase64STR(decodedData);
        const downloadLink = document.createElement("a");

        //nuevo metodo

        const worksheet = XLSX.utils.json_to_sheet(decodedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "ranking-torneo.xlsx");
    
        //downloadLink.href = decodedData;
        
        //downloadLink.download = "ranking-torneo.xlsx";
        
        //downloadLink.click();
        /*fs.writeFile(outputPath, decodedData, (error) => {
        if (error) {
            console.error('Error writing the PDF file:', error);
        } else {
            console.log('PDF file saved successfully at', outputPath);
        }
        });*/
    }

    useEffect(() => {
        cargarDatosSelect()
    }, []);

    return (
        <>
            <body className='body_carousel_3_sss'>
                <div className='carousel_grid'>
                    <div className='carousel_div'>

                        {data.map((item, index) => {  
                            const aux_index = index
                            return(
                            <>
                                {data[aux_index].src.map((img, index) => { return(
                                    <div className={
                                            (indexCarousel === aux_index) ? 
                                                (index) % 3 == 0  ? 
                                                    'div_image'
                                                    :  
                                                    'div_image_plus2' 
                                                : 
                                                'div_image div_image_hidden' 
                                        }>
                                        {
                                            img ?
                                            <>
                                                <a onClick={() => {
                                                    handleDonwloadExcel(index)
                                                }}>
                                                    <img src={img} key={aux_index} className={indexCarousel === aux_index ? 'carousel_img' : 'carousel_img carousel_img_hidden' }/>
                                                </a>
                                                <div className='card_title'>
                                                    <h3>{data[aux_index].titulo[index]}</h3>
                                                    <p>{data[aux_index].decripcion[index]}</p>
                                                </div>
                                            </>
                                            :
                                            <div className='espacio vacio'></div>          
                                        }
                                        
                                    </div>
                                )})

                                }
                            </>
                            )})
                        }
                    </div>
                    <div className='arrow_div_left'>
                        <IoIosArrowBack className={(dataSelect.length == 0)?'arrow arrow_none':'arrow arrow_left'} onClick={previousSlide}/>
                    </div>
                    <div className='arrow_div_right'>
                        <IoIosArrowForward className={(dataSelect.length == 0)?'arrow arrow_none':'arrow arrow_right'} onClick={nextSlide}/>
                    </div>
                    
                    
                </div>
            </body>
            
        </>
    )
}

export default Carousel_3_finalizado;